.class1 {
  position: absolute;
  top: 24px;
  left: 6px;
  width: 10px;
  height: 10px;
  background: #FFF;
  border-radius: 50%;
  opacity: 0.7;
}

.class2 {
  position: absolute;
  top: 24px;
  left: 6px;
  width: 10px;
  height: 10px;
  background: #fff;
  border: 2px solid #1F2667;
  border-radius: 50%;
  /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);*/
}

.class3 {
  position: absolute;
  top: 20px;
  left: 13px;
  width: 0px;
  height: 3px;
  background: #094679;
  border-radius: 3px;
}


/*.class1 {
  position: absolute;
  top: 4px;
  left: 6px;
  width: 14px;
  height: 50px;
  background: #FFF;
  border-radius: 14px;
  opacity: 0.7;
}

.class2 {
  position: absolute;
  top: 6px;
  left: 8px;
  width: 10px;
  height: 46px;
  background: #fff;
  border: 2px solid #1F2667;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.class3 {
  position: absolute;
  top: 9px;
  left: 13px;
  width: 3px;
  height: 35px;
  background: #094679;
  border-radius: 3px;
}*/