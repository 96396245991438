@import 'src/assets/scss/variables';

@font-face {
  font-family: Asap;
  src: url('../fonts/Asap-Medium.ttf') format('truetype');
}
@font-face {
  font-family: Asap;
  src: url('../fonts/Asap-Regular.ttf') format('truetype');
}
@font-face {
  font-family: Asap;
  src: url('../fonts/Asap-SemiBold.ttf') format('truetype');
}

// * {
//   font-family: var(--font-name) !important;
// }

html,
body {
  //-webkit-user-select: none; /* Safari */
  //-moz-user-select: none; /* Firefox */z
  //-ms-user-select: none; /* IE10+/Edge */
  //user-select: none; /* Standard */
  overflow: overlay;
  overflow-x: hidden;
  scrollbar-width: thin;
  padding: 0px !important;
  // overscroll-behavior-y: contain;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    position: absolute;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
    opacity: 0.2;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar-corner {
    width: 0;
  }
}

* {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
    opacity: 0.2;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar-corner {
    width: 0;
  }
}

.scroll {
  overflow: overlay;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
    opacity: 0.2;
  }
  &::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

*::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
  opacity: 0.2;
}

*::-webkit-scrollbar-thumb {
  background: #757575;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.thin-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 4px;
  }
}

body .snackbar {
  top: calc(env(safe-area-inset-top) + 15px);
}
p {
  margin: 0;
}
a {
  text-decoration: none;
  outline: none;
  &:focus {
    outline: none;
  }
}

.stalk-app {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

body {
  .flex-2 {
    flex: 2;
  }
  .flex-1 {
    flex: 1;
  }

  .flex-0 {
    flex: 0;
  }

  .opc-7 {
    opacity: 0.7;
  }

  .direction-ltr {
    direction: ltr;
  }

  .direction-rtl {
    direction: rtl;
  }

  .en-text {
    font-family: Asap, serif;
  }

  .text-end {
    text-align: end;
  }

  .wrap-text {
    word-wrap: anywhere;
    text-align: left;
    word-break: break-word;
  }
  .overflow-wrap {
    overflow-wrap: anywhere;
  }
  .text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.small-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 4px;
  }
}

.text-elipses {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.typeWriter {
  color: var(--dark-text-color);
  font-weight: 100;
  strong {
    font-size: large;
  }
}
