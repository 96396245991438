.icon {
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-inline: 3px;

  svg {
    vertical-align: middle;
  }
}
