.InputContainer {
  background-color: #f2efea;
  border-radius: 30px;
  height: 30px;
  padding-inline: 10px;
  margin-top: 10px;
  color: var(--primary);
  font-size: 14px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    box-shadow: 0 0 0px 1000px white inset !important;
    background-color: white !important;
    color: #000 !important;
}